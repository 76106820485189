<template>
  <v-card class="dialog-card">
    <v-toolbar
      v-if="hasHeaderSlot"
      flat
    >
      <v-toolbar-title class="px-2">
        <slot name="header" />
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        @click="closeDialog()"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-card-text
      class="dialog-content pb-0"
      :class="noPadding ? 'pa-0' : ''"
    >
      <slot />
    </v-card-text>
    <v-card-actions
      v-if="hasFooterSlot"
      class="dialog-actions-bar pa-4"
    >
      <slot name="footer" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { computed } from '@vue/composition-api';
import { actions } from '../../store/modules/dialog';

export default {
  name: 'GeneralDialogTemplate',
  props: {
    noPadding: Boolean,
  },
  setup(props, ctx) {
    const hasFooterSlot = computed(() => !!ctx.slots.footer);
    const hasHeaderSlot = computed(() => !!ctx.slots.header);
    return {
      ...actions,
      hasFooterSlot,
      hasHeaderSlot,
    };
  },
};
</script>

<style scoped></style>
