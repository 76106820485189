<template>
  <GeneralDialogTemplate no-padding>
    <template slot="header">
      <span class="font-weight-medium">Lisa uus liige</span>
    </template>
    <v-row
      no-gutters
      class="pa-5 form-width"
    >
      <v-col
        v-for="(field, index) in fields"
        :key="index"
        class="px-2"
        cols="12"
      >
        <component
          :is="field.component"
          outlined
          dense
          :label="field.label"
          v-bind="field.props"
          @change="onFieldChange($event, field.name)"
        />
      </v-col>
      <v-col>
        <v-row
          no-gutters
          justify="end"
        >
          <v-btn
            color="primary"
            text
            @click="onCloseClick"
          >
            Loobu
          </v-btn>
          <v-btn
            color="primary"
            @click="onSaveClick"
          >
            Salvesta
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </GeneralDialogTemplate>
</template>
<script>
import { reactive } from '@vue/composition-api';
import AuthUserModule from '@/store/modules/auth_user';
import dialogModule from '@/store/modules/dialog';
import GeneralDialogTemplate from '@/components/templates/GeneralDialogTemplate.vue';
import { VTextField, VSelect } from 'vuetify/lib';

export default {
  components: {
    GeneralDialogTemplate,
    VTextField,
    VSelect,
  },
  setup() {
    const { closeDialog } = dialogModule();
    const { createNewUser } = AuthUserModule();
    const orgTitles = ['ksv!', 'vil!', 'nrl!'];
    const orgBranches = [
      {
        name: 'Tallinn',
        value: 'tallinn',
      },
      {
        name: 'Tartu',
        value: 'tartu',
      },
    ];
    // const fields = ["id", "primary_email", "first_name", "last_name", "org_branch", "org_title", "phone"];
    const fields = [
      {
        name: 'id',
        label: 'Viki kasutaja (UID)',
        component: 'v-text-field',
      },
      {
        name: 'primary_email',
        label: 'E-posti aadress',
        component: 'v-text-field',
      },
      {
        name: 'first_name',
        label: 'Eesnimi',
        component: 'v-text-field',
      },
      {
        name: 'last_name',
        label: 'Perekonnanimi',
        component: 'v-text-field',
      },
      {
        name: 'phone',
        label: 'Telefon',
        component: 'v-text-field',
      },
      {
        name: 'org_branch',
        label: 'Koondis',
        component: 'v-select',
        props: {
          'item-text': 'name',
          'item-value': 'value',
          items: orgBranches,
        },
      },
      {
        name: 'org_title',
        label: 'Liikmestaatus',
        component: 'v-select',
        props: {
          items: orgTitles,
        },
      },
    ];
    const state = reactive({
      user: {},
    });
    const onFieldChange = (ev, field) => {
      Object.assign(state.user, { [field]: ev });
    };
    const onSaveClick = async () => {
      try {
        await createNewUser(state.user);
        closeDialog();
      } catch (err) {
        console.log('err', err);
      }
    };
    const onCloseClick = () => {
      closeDialog();
    };
    return {
      onFieldChange,
      onCloseClick,
      onSaveClick,
      fields,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-width {
    width: 500px
}
</style>
